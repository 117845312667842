import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductStateService } from '../../../core/product-state.service';

@Component({
  selector: 'app-product-list-page',
  templateUrl: './product-list-page.component.html',
  styleUrls: ['./product-list-page.component.scss']
})
export class ProductListPageComponent implements OnInit {
  public products;
  constructor(private productStateService: ProductStateService,
              private router: Router) { }

  ngOnInit(): void {
    this.productStateService.state$.subscribe(productState => 
      this.products = productState.products
    )
  }

  selectProduct(product) {
    this.productStateService.patchState({ currentProduct: product })
    this.router.navigateByUrl(`/product-page?id=${product.id}`);
  }

}
