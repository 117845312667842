import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
import settings from '../../assets/settings.json';
import { AppStateService } from './app-state.service';

@Injectable()
export class ServiceBus {
  public pagePath = '';
  public adsSetup = false;
  public intialAdsSetup = false;

  constructor(
    private router: Router,
    private appStateService: AppStateService,
  ) { }

  public initialize() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pagePath = event.urlAfterRedirects;
      }
    });
    return this.appStateService.initialize(settings);

  }

}
