import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capabilities-page',
  templateUrl: './capabilities-page.component.html',
  styleUrls: ['./capabilities-page.component.scss']
})
export class CapabilitiesPageComponent implements OnInit {

  constructor() {
    
  }

  ngOnInit(): void {
  }

}
