import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ApplicationContext, getUrls } from '@thedevshop/web-ng';
import { ServiceBus } from './service-bus';

export function initFactory(injector: Injector) {
  return () => {


    const appContext = injector.get(ApplicationContext);
    const http = injector.get(HttpClient);
    (<any>appContext).httpProvider.setHttpClient(http);
    appContext.urls = getUrls();

  };
}
