import { Inject, Injectable } from '@angular/core';
import { ClientFuncService } from '@thedevshop/core';
import { BaseRepository } from '@thedevshop/core';
import { ApplicationContext, USER_STATE_SERVICE } from '@thedevshop/web-ng';
import { Observable } from 'rxjs';
import { UserStateService } from './user-state.service';
@Injectable({
    providedIn: 'root',
})
export class MessageService extends ClientFuncService<any> {

    modelName = 'Message';

    constructor(applicationContext: ApplicationContext, userStateService: UserStateService) {
        super(applicationContext, new BaseRepository<any>(applicationContext, 'Message'), userStateService);
    }

    public supportRequest(supportFormValue: any): Observable<any> {
        return this.postFunc('supportRequest', supportFormValue);
    }

    public contactUs(message: any): Observable<any> {
        return this.postFunc('contactUs', message);
    }

}