<div class="communityPage">
    <app-page-header sectionTitle="Community Engagement" pageTitleClass="community" sectionSubTitle="GIVING BACK WHERE IT MATTERS"
        backgroundImg="assets/img/2022/AerasSub2Darkened.jpg"></app-page-header>
    <section class="copyBlock imgRight py-5">
        <div class="container">
            <div class="row align-items-center py-5">
                <div class="col-12 col-md-6">
                    <h3>LAW ENFORCEMENT<br>
                        TRAINING</h3>
                    <p>Our commitment spans far beyond technology and R&D. We have over 15 years of experience in the drone industry 
                        and with the accumulated knowledge that we’ve garnered, we are committed to giving back to our community. 
                    </p>
                    <p>Our seasoned team has developed training programs for local law enforcement agencies, safety protocols, and training 
                        manuals for local and national authorities. Our training and support programs are designed to spread the awareness of 
                        drones, drone technology, and broaden the knowledge base of our industry.</p>
                    <p>Our lead instructor currently sits as the Chair of the Economic Development Committee for the UAS Integration Task Force (PennDot).</p>
                </div>
                <div class="col-12 col-md-6">
                    <img src="assets/img/2022/ZenmuseP1-OnAicraft_2048x2048.png" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
</div>