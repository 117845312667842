import { Component, OnInit} from '@angular/core';
import { ExtraOptions } from '@angular/router';

@Component({
  selector: 'app-community-page',
  templateUrl: './community-page.component.html',
  styleUrls: ['./community-page.component.scss']
})
export class CommunityPageComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}
