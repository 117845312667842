<app-page-header sectionTitle="Industries" backgroundImg="assets/img/indHeader.jpg"></app-page-header>
<div class="industries">
    <section class="intro">
        <div class="container slideIn">
            <div class="row pb-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Stadiums, Arenas &amp; Large Outdoor Areas</h4>
                    <img src="assets/img/stadium.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>Sporting events, concerts and large gatherings have been postponed due to the Covid-19 pandemic. Returning to some normalcy of hosting these events means venues and spaces will need to ensure their facilities are utilizing the highest
                        level of safety and best products available on the market. While we have focused on our cost and time savings of our electrostatic services and products, the biggest advantage is ensuring full coverage of sanitation leaving no
                        room for human error.</p>
                    <p>Give your fans, employees and all parties involved the peace of mind knowing you, as a venue, are utilizing the best service and product currently on the market, one that will save your team and/or company time and money with the highest
                        level of efficiency available.
                    </p>
                </div>
            </div>
            <hr>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Agriculture</h4>
                    <img src="assets/img/agriculture.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>Looking to increase efficiency in your farming process? Increasing or maintaining crop yields is always a main focus for any farm or vineyard and requires proper fertilization, protection barriers without soil compaction or crop damage.</p>
                    <p>Our patent pending electrostatic technology is the first of its kind. Our drone is more powerful than anything currently available, allowing the particles to be charged longer for better coverage than electrostatic nozzles. Using our
                        technology allows delivery of a product or spray that will wrap evenly around each plant giving you full coverage of your crop without ever having to disrupt the soil or plants themselves. Additionally, while most worries with
                        drone spraying that drifting would be of issue, our drone has the ability to fly closer to the crops (3 feet above) than larger aircrafts typically used in the industry.</p>
                    <p>Spraying with our electrostatic drone can easily lead to improved coverage, improved retention and savings in spray mix, reduction in losses to soil, and efficacy of both insect and disease control.</p>
                </div>
            </div>
            <app-cta buttonText="Disinfect Now!"></app-cta>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Warehouses &amp; Fulfillment Centers</h4>
                    <img src="assets/img/warehouse.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>Many warehouses have been deemed as essential in the wake of this Covid-19 pandemic. As an employer you need to ask yourself, are you doing all you can to protect them?</p>
                    <p>While PPE is important, disinfecting these areas to ensure contaminated spaces are being disinfected properly is often overlooked, time consuming and requires a lot of attention and workers.</p>
                    <p>With our drone technology we significantly reduce the amount of time required and resulting cost. Most importantly our service eliminates any risk of human error.</p>
                </div>
            </div>
            <hr>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Parks &amp; Recreation Facilities</h4>
                    <img src="assets/img/playground.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>There are many applications with our services and products within the public sector at both a local and state level. Aeras Fog has the ability to mobilize drones to disinfect and sanitize playgrounds, sidewalks or large community spaces,
                        as well as, smaller spaces with our commercial fogger backpacks and mini units.</p>
                    <p>While electrostatic products have been utilized to disinfect a variety of public areas throughout the world, Aeras electrostatic technology substantially increases the effectiveness of spraying community spaces. Our technology can
                        assist and help put communities at ease with a safe and effective method of sanitization.</p>
                </div>
            </div>
            <hr>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Public &amp; Private Transportation</h4>
                    <img src="assets/img/transportation.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>Planes, trains and automobiles are all especially confined spaces that have left operators, passengers and family members nervous to venture out, and left businesses scrambling to ensure customers and staff safety. </p>
                    <p>The combination of our commercial backpack sprayer and the Aeras mini affords our customers a comprehensive solution to all of their sanitization needs. Whether large public transportation projects or keeping your personal vehicles
                        sanitized, our products will provide the public and your family with the safest environments possible.</p>
                </div>
            </div>
            <app-cta buttonText="Disinfect Now!"></app-cta>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Residential &amp; Commercial Real Estate</h4>
                    <img src="assets/img/realestate.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>In the wake of Covid-19, residential and commercial real estate companies and their agents have found themselves in an environment that makes it almost impossible to provide clients with the level of service that they have consistently
                        offered. The agent client relationship has always been a personal one that typically continues into perpetuity. The Aeras mini will allow agents to ensure the highest level of safety for both the homes they list and the vehicles
                        they drive. The primary objective of every real estate company is customer service and satisfaction. The current environment today and moving forward requires a new solution and places the safety of their clients as the number
                        one priority.</p>
                </div>
            </div>
            <hr>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Healthcare Facilities</h4>
                    <img src="assets/img/healthcare.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>Hospitals, assisted living facilities, physical therapy centers, retirement homes all provide care that we and our loved ones need. The Aeras group provides solutions to ensure those spaces stay safe and sanitized, so they can continue
                        focusing on us when we need it most. With our commercial fogger and Aeras mini these facilities can implement sanitation needs with ease and efficiency and know they are utilizing the best products on the market.</p>
                </div>
            </div>
            <hr>
            <div class="row py-5 align-items-center">
                <div class="col-12 col-md-6 industryItem flipIn">
                    <h4>Educational &amp; Religious Institutions</h4>
                    <img src="assets/img/school.jpg" />
                </div>
                <div class="col-12 col-md-6">
                    <p>The single biggest driver for the success of schools and their students, and for places of worship and their patrons, is attendance. The Covid-19 pandemic has seriously compromised these institutions from remaining open, where students
                        can learn and people can pray, and have upended the societal norms with which we all become accustomed.</p>
                    <p>From the classroom to the church, synagogue, temple, or mosque, our line of services and products are essential for any daycare, school, university or religious gathering center, providing the safest environment through the most efficient
                        and cost effective delivery methods. The ultimate objective for all of us is to ensure the safety and educational and spiritual growth of those we love.</p>
                </div>
            </div>
        </div>
    </section>
</div>