<div class="aboutUsPage">
    <app-page-header sectionTitle="About Aeras" pageTitleClass="aboutUs"
        backgroundImg="assets/img/2022/aboutHeader.jpg"></app-page-header>
    <div class="aboutPage">
        <section class="intro">
            <div class="container">
                <div class="row py-5 align-items-center">
                    <div class="col-12 col-md-6 introText">
                        <p>Since 2020, AERAS has been an international leader in charged-electrostatic drone innovation.
                            Headquartered in Pittsburgh, Pennsylvania, AERAS is an emerging technology company
                            specializing in drone applications. </p>

                        <p>The company challenges the standards of drone technology with its revolutionizing research.
                            Its expertise empowered the company to develop and build new charged-electrostatic
                            technology that changes the rules for drone usage. </p>
                        <p>Having patented the only drone in the world operating with a charged-electrostatic
                            disbursement system, AERAS was the first company in the United States certified by the FAA
                            to use drones specifically to sanitize outdoor venues against COVID-19. Its AER-Force
                            technology powered the first charged-electrostatic spraying system integrated on a drone,
                            used to sanitize the 2021 Kentucky Derby and PPG Paints Arena. AERAS’ technologies and suite
                            of products have created a new standard in large-scale sanitization. </p>
                        <p>But AERAS isn’t just about sanitization. </p>
                        <p>Their research and development division has positioned the company at the forefront of
                            utilizing drones to protect human life while preserving the environment. That means finding
                            innovative ways to use drones for search and rescue, power washing and environmentally
                            responsible crop management. AERAS’ Green Initiative combines using titanium dioxide
                            solution with a charged-electrostatic distribution.</p>

                        <p>AERAS isn’t just at the forefront of drone development. It’s setting the standard for
                            tomorrow.</p>

                    </div>
                    <div class="col-12 col-md-6 introQuote h-100">
                        <div class="outerDrop">
                            <div class="innerDrop">
                                <div class="contentWrapper">
                                    <p class="quote">put the technology in the hands of good people and watch them
                                        change the world for the better.
                                    </p>
                                    <p class="quoteSource">
                                        Nick<span>Brucker</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section class="ourTeam">
            <div class="container">
                <div class="row py-5">
                    <div class="col-12 col-md-7 col-lg-6">
                        <div class="row py-3">
                            <div class="col">
                                <h2>Our Team</h2>
                            </div>
                        </div>
                        <div class="row py-3">
                            <div class="col-6 teamMember">
                                <p class="name">Eric Lloyd</p>
                                <p class="title">CEO & CO-FOUNDER</p>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><a
                                            href="https://www.linkedin.com/in/eric-lloyd-5b51851b2/"><i
                                                class="fab fa-linkedin-in"></i></a></li>
                                    <li class="list-inline-item"><a href="mailto:eric@aerasfog.com"><i
                                                class="fal fa-at"></i></a></li>
                                </ul>
                            </div>
                            <div class="col-6 teamMember">
                                <p class="name">JUSTIN MELANSON</p>
                                <p class="title">CTO & CO-FOUNDER</p>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><a
                                            href="https://www.linkedin.com/in/justin-melanson-424a161b4/"><i
                                                class="fab fa-linkedin-in"></i></a></li>
                                    <li class="list-inline-item"><a href="mailto:justin@aerasfog.com"><i
                                                class="fal fa-at"></i></a></li>
                                </ul>
                            </div>
                            <div class="col-6 teamMember">
                                <p class="name">NICK BRUCKER</p>
                                <p class="title">CMO & CO-FOUNDER</p>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><a
                                            href="https://www.linkedin.com/in/nick-brucker-7807b413/"><i
                                                class="fab fa-linkedin-in"></i></a></li>
                                    <li class="list-inline-item"><a href="mailto:nick@aerasfog.com"><i
                                                class="fal fa-at"></i></a></li>
                                </ul>
                            </div>
                            <div class="col-6 teamMember">
                                <p class="name">JIM ABEL</p>
                                <p class="title">Global Business Development</p>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><a href="https://www.linkedin.com/in/jcaii/"><i
                                                class="fab fa-linkedin-in"></i></a></li>
                                    <li class="list-inline-item"><a href="mailto:jim@aerasfog.com"><i
                                                class="fal fa-at"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>