<app-page-header sectionTitle="{{product.productName}}" backgroundImg="assets/img/techHeader.jpg" sectionSubTitle="IDENTIFYING DETAILS THAT
MAKE A DIFFERENCE"
 *ngIf="product">
</app-page-header>
<section class="productPage pb-5" *ngIf="product">
    <div class="container">
        <div class="row py-5 align-items-center">
            <div class="col-12 col-md-6 flipIn">
                <img [src]="product.productImgUrl">
            </div>
            <div class="col-12 col-md-6">
                <h4>{{product.productName}}</h4>
                <h6 [innerHTML]="product.productPrice"></h6>
                <p>{{product.productDescriptionShort}}</p>
                <a href="/contact" class="btn btn-primary" *ngIf="!product.isAvailable">Contact Us for Pricing Information</a>
                <a href="/checkout" class="btn btn-primary" *ngIf="product.isAvailable">Add To Cart</a>
                <p class="pt-3">Call for Wholesale Pricing</p>
            </div>
        </div>
        <hr>
        <div class="row py-5">
            <div class="col">
                <!-- <div>{{product.productDescriptionLong}}</div> -->
                <div [innerHTML]="product.productDescriptionLong"></div>
            </div>
        </div>

    </div>
</section>