<nav class="navbar navbar-expand-md navbar-light ecommerceBar">

    <!-- Links -->
    <div class=" collapse navbar-collapse " id="basicExampleNav1 ">

        <!-- Right -->
        <ul class="navbar-nav mr-auto ">
            <li class="nav-item ">
                <a href="# " class="nav-link ">Shop</a>
            </li>
            <li class="nav-item ">
                <a href="# " class="nav-link ">Contact</a>
            </li>
            <li class="nav-item ">
                <a href="# " class="nav-link ">Sign in</a>
            </li>
            <li class="nav-item ">
                <a href="# " class="nav-link ">Sign up</a>
            </li>
        </ul>
        <!-- Right -->
        <ul class="navbar-nav ml-auto ">
            <li class="nav-item ">
                <a href="# " class="nav-link navbar-link-2 ">
                    <span class="badge badge-pill red ">1</span>
                    <i class="fas fa-shopping-cart pl-0 "></i>
                </a>
            </li>
        </ul>

    </div>
    <!-- Links -->

</nav>
<!-- Navbar -->