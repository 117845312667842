<form [formGroup]="contactForm">
    <div class="form-group">
        <input type="email" email="true" class="form-control" id="email" placeholder="Email *" formControlName="email" required>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <input type="text" class="form-control" id="firstName" placeholder="First Name *" formControlName="firstName" required>
        </div>
        <div class="form-group col-md-6">
            <input type="text" class="form-control" id="lastName" placeholder="Last Name *" formControlName="lastName" required>
        </div>
    </div>
    <div class="form-row">
        <!-- <div class="form-group col-md-6">
            <input type="text" class="form-control" id="company" placeholder="Company" formControlName="company" required>
        </div> -->
        <div class="form-group col">
            <input type="text" class="form-control" id="phone" placeholder="Phone Number *" formControlName="phone">
        </div>
    </div>
    <!-- <div class="form-group">
        <label>What are you interested in?</label>
        <select class="form-control" formControlName="interests" required>
            <option>Aeras Electrostatic Drone Service</option>
            <option>Aeras Backpacks</option>
            <option>Aeras mini</option>
            <option>Wholesale options</option>
            <option>Retail options</option>
        </select>
    </div> -->
    <div class="form-group">
        <textarea class="form-control" placeholder="How can we help? *" id="notes" formControlName="notes" required></textarea>
    </div>

    <div class="my-3 text-center formMessage">
        <h5 *ngIf="message" class="align-self-center text-alert text-uppercase"><img src="assets/img/alert.svg"> {{message}}</h5>
    </div>
    
    <button type="submit" class="btn btn-primary" (click)="submitForm()" [disabled]="contactForm.invalid">Submit</button>
</form>