import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductStateService } from '../../../core/product-state.service';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent implements OnInit {
  public product: any;

  constructor(private productStateService: ProductStateService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.productStateService.state$.subscribe(productState => {
      if (productState.currentProduct) {
        this.product = productState.currentProduct;
      } else {
        this.route.queryParams.subscribe(params => {
          let productId = params.id;
          if (productId) {
            this.productStateService.setCurrentProductById(productId);
          }
        })
      }
      
    })
  }

}
