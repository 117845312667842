<section class="container industry py-5">
    <div class="row align-items-center">
        <div class="col-12 col-md-4">
            <h2>Industries<br>we serve</h2>
            <a routerLink="/industry" class="btn btn-primary mb-3">Learn More</a>
        </div>
        <div class="col">
            <div class="row no-gutters">
                <div class="col-6 industryItem">
                    <div class="mask"></div>
                    <img src="assets/img/stadium.jpg" />
                    <h4>STADIUM<br>&amp; ARENAS</h4>
                    <p>Giving fans and players a piece of mind while saving the teams and organizations the time and money it takes to utilize handheld devices throughout the entire stadium/arena. Our electrostatic drones not only ensure the fullest coverage
                        possible of any disinfectant, they are also able to cover a large amount of space in an extremely quick fashion.</p>
                </div>
                <div class="col-6 industryItem">
                    <div class="mask"></div>
                    <img src="assets/img/vineyard.jpg" />
                    <h4>VINEYARDS</h4>
                    <p>Our drone allows us to navigate the fields and the land from the sky. This modern methodology allows us to improve crop performance and farm efficiency in less time with zero tampering or contamination of soil and crops. </p>
                </div>
                <div class="col-6 industryItem">
                    <div class="mask"></div>
                    <img src="assets/img/concert.jpg" />
                    <h4>LARGE OUTDOOR VENUES</h4>
                    <p>Whether a concert venue or outdoor shopping mall, we can help you keep your customers safe and have peace of mind by disinfecting large outdoor areas with efficiency.</p>
                </div>
                <div class="col-6 industryItem">
                    <div class="mask"></div>
                    <img src="assets/img/warehouse.jpg" />
                    <h4>WAREHOUSES</h4>
                    <p>We can clean large warehouse spaces with ease putting the safety of your employees first.</p>
                </div>
                <div class="col-12 industryItem">
                    <div class="mask"></div>
                    <img src="assets/img/playground.jpg" />
                    <h4>CITIES</h4>
                    <p>Yes. Cities. We are able to assist cities with the ease of our sanitizing methods of playgrounds, sidewalks, or any large community spaces. With our technology and our partners’ 90-day disinfectants we can help put communities at ease
                        and lessen the burden of city workers at risk doing it by hand, as our drone pilots can disinfectant from afar.</p>
                </div>
            </div>
        </div>
    </div>
</section>