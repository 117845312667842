<section class="technology py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 product">
                <!-- <img src="assets/img/drone.png"> -->
                <img class="u-fadeCycle-1" src="assets/img/products/scDrone.jpg">
                <img class="u-fadeCycle-2" src="assets/img/handheld.png">
                <img class="u-fadeCycle-3" src="assets/img/products/backpack.jpg">
            </div>
            <div class="col-12 col-md-7">
                <h2>Technology</h2>
                <p>AERAS is a one of a kind disinfectant application process for large areas and venues that provides more coverage and better results than anything currently offered on the market.</p>
                <p>How does our technology work? The disinfectant particles are charged at a higher voltage than any other electrostatic technology available, allowing for more time for the positively charged disinfectant to seek and land on its grounded
                    surfaces, providing more coverage.</p>
                <ul>
                    <li>Drone equipped with patent-pending electrostatic sprayer</li>
                    <li>Electrostatically charged particles achieve a 75x value greater than the force of gravity</li>
                    <li>Maintains a particle charge 100x longer than existing technologies</li>
                    <li>Internally fabricated components to account for any changes in environmental condition</li>
                    <li>Aeras Fog’s proprietary software can change voltage at the touch of a button to safely spray anything from empty seats to sensitive electronic equipment</li>
                    <li>Autonomous 3D topographical mapping FAA Approved</li>
                    <li>Indoor/Outdoor sanitization capabilities 20 acres/coverage per hour</li>
                </ul>
                <div class="text-center">
                    <a routerLink="technology" class="btn btn-primary mt-3">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>