<footer class="container-fluid py-5">
    <div class="container">
        <div class="row align-items-start">
            <div class="row py-3 align-items-center text-center">
                <div class="col logo">
                    <img src="assets/img/logo_light.svg">
                </div>
            </div>
            <div class="row py-3">
                <div class="col navi left">
                    <ul class="list-unstyled">
                        <li><a routerLink="/">Home</a></li>
                        <!-- <li><a routerLink="/about">Mission</a></li>
                    <li><a routerLink="/products">Investor Relations</a></li>
                    <li><a routerLink="/services">Press+Media</a></li>
                    <li><a routerLink="/services">FAQs</a></li> -->
                        <li><a routerLink="/products">Products</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</footer>