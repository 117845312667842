<!-- 
<div class="container-fluid bgTopo">
    <app-about-section id="about"></app-about-section>
</div>
<div class="technologyWrapper">
    <app-technology-section id="technology"></app-technology-section>
</div> -->
<!-- <app-industry-section id="industry"></app-industry-section> -->
<!-- <app-accolades-section></app-accolades-section> -->

<div class="homepage">
<section class="container-fluid homePageNav technology"
    style="background: url('assets/img/2022/AerasImage1Darkened.jpg');">
    <div class="row">
        <div class="col">
            <div class="callOutWrapper">
                <div class="callOut left">
                    <h2><span>Technology</span>Innovative Technology<br>Solutions/Environment</h2>
                    <a routerLink="/technology" class="btn btn-primary">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container-fluid homePageNav capabilities"
    style="background: url('assets/img/2022/AerasImage2Darkened.jpg');">
    <div class="row">
        <div class="col">
            <div class="callOutWrapper">
                <div class="callOut right">
                    <h2><span>Capabilities</span>Science Meets<br>Common Sense</h2>
                    <a routerLink="/capabilities" class="btn btn-primary">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container-fluid homePageNav products"
    style="background: url('assets/img/2022/AerasImage3Darkened.jpg');">

    <div class="row">
        <div class="col-12">
            <div class="callOutWrapper">
                <div class="callOut left">
                    <h2><span>Products</span>Identifying Details That<br>Make A Difference</h2>
                    <a routerLink="/products" class="btn btn-primary">Learn More</a>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- <section class="container-fluid homePageNav pressMedia"
    style="background: url('assets/img/2022/AerasImage4Darkened.jpg');">
    <div class="container">
        <div class="row">
            <div class="callOutWrapper">
                <div class="callOut right">
                    <h2><span>Press + Media</span>The 147th<br>Kentucky Derby</h2>
                    <a routerLink="/community" class="btn btn-primary">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
</div>