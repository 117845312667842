import { Injectable, Inject } from '@angular/core';
import { Observable, combineLatest, Subject } from 'rxjs';
import { BaseStateService } from './base-state.service';
import {
  UserState,
  ModelFactory,
  TranslationService
} from '@thedevshop/core';
import {
  ANON_TOKEN,
  ApplicationContext
} from '@thedevshop/web-ng';
import { filter } from 'rxjs/operators';
export class AppState {
  ShowProfile = false;
  ShowHeader = true;
  IsLoading = false;
  isScrollingDown: boolean;
  isMenuOpened: boolean;
  Message: any;
  Error: string;
  IsNative = false;
  IsNativeAndroid = false;
  IsPwa = false;
  IsMobile = false;
  toastMessage: string;
  toastType: string;
  settings: any;  // These settings are merged inside of the app assets and an api call
  promptTemplates: any;
  allowRefresh = true; // tug refresh (is only true if scrolled to very top of screen)
  // TODO: Remove.  Use ProfileStateService
}

export enum UserType {

}

const getParameterByName = (name) => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) { return null; }
  if (!results[2]) { return ''; }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

@Injectable({
  providedIn: 'root'
})
export class AppStateService extends BaseStateService<AppState> {
  initialize(settings: { title: string; }) {
    throw new Error('Method not implemented.');
  }
  onSettingsLoaded$: Subject<any> = new Subject();
  onModalOpened$: Subject<AppState> = new Subject();
  onToastOpened$: Subject<AppState> = new Subject();
  triggerRefresh$: Subject<AppState> = new Subject();

  onSwipeLeft$: Subject<boolean> = new Subject();
  onSwipeRight$: Subject<boolean> = new Subject();

  constructor(
    stateFactory: ModelFactory<AppState>,
    @Inject(ANON_TOKEN) private anonToken: string,
    private applicationContext: ApplicationContext,
    private translationService: TranslationService,
  ) {
    super(new AppState(), stateFactory, null);
  }

  setScrollingDirection(direction: 'UP' | 'DOWN') {
    const appState = this.stateModel.get();
    appState.isScrollingDown = direction === 'DOWN';
    this.stateModel.set(appState);
  }



  public toggleProfile() {
    const state = this.stateModel.get();
    state.ShowProfile = !state.ShowProfile;
    this.stateModel.set(state);
  }


  public toggleMenu(isOpen?: boolean) {
    const state = this.stateModel.get();

    if (isOpen === state.isMenuOpened) {
      return;
    }

    if (typeof isOpen !== 'undefined') {
      state.isMenuOpened = isOpen;
    } else {
      state.isMenuOpened = !state.isMenuOpened;
    }
    if (state.isMenuOpened) {
    }
    this.stateModel.set(state);
  }

  public toggleHeader(showHeader?: boolean) {
    const state = this.stateModel.get();
    state.ShowHeader = showHeader;
    setTimeout(() => {
      this.stateModel.set(state);
    }, 50);

  }

  public setToast(message: string, type: string = 'info') {
    const state = this.getState();
    state.toastMessage = message || null;
    state.toastType = type || null;

    this.onToastOpened$.next(state);
    this.setState(state);
  }

  public toggleLoading(isLoading?: boolean) {
    const state = this.stateModel.get();
    state.IsLoading = isLoading;
    this.stateModel.set(state);
  }

  showNotification(notification: { title: string, body: string, icon?: string, click_action?: string, image?: string }) {
    const state = this.stateModel.get();
    state.Message = notification;
    this.stateModel.set(state);
  }

  dismissNotification() {
    const state = this.stateModel.get();
    state.Message = null;
    this.stateModel.set(state);
  }

  setError(errorMessage: string) {
    const state = this.getState();
    state.Error = errorMessage;
    this.setState(state);
  }

  setIsNative(isNative: boolean) {
    const state = this.getState();
    state.IsNative = isNative;
    this.setState(state);
  }

  setIsNativeAndroid(isNative: boolean) {
    const state = this.getState();
    state.IsNativeAndroid = isNative;
    this.setState(state);
  }

  setIsMobile(isMobile: boolean) {
    const state = this.getState();
    state.IsMobile = isMobile;
    this.setState(state);
  }

  setIsPwa(isPwa: boolean) {
    const state = this.getState();
    state.IsPwa = isPwa;
    this.setState(state);
  }

  setRefresh(refresh: boolean) {
    const state = this.getState();
    state.allowRefresh = refresh;
    this.setState(state);
  }

}
