import { NgModule, Optional, SkipSelf, ModuleWithProviders,APP_INITIALIZER, Injector } from '@angular/core';


import { USER_STATE_SERVICE, ApplicationContext, getUrls } from '@thedevshop/web-ng';

import { UserService, AppContextUrls } from '@thedevshop/core';
import { HttpClient } from '@angular/common/http';
import { initFactory } from './core/initFactory';
import { ServiceBus } from './core/service-bus';
import { AppStateService } from './core/app-state.service';




export function getUserService(appContext: ApplicationContext) {
  return new UserService(appContext);
}

export function getAppContext(http: HttpClient, urls: AppContextUrls) {
  return new ApplicationContext(http, urls);
}


@NgModule({

})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AppStateService,
        ServiceBus,
        { provide: UserService, useFactory: getUserService, deps: [ApplicationContext] },
        { provide: AppContextUrls, useFactory: getUrls, deps: [] },
        { provide: ApplicationContext, useFactory: getAppContext, deps: [HttpClient, AppContextUrls] },
        {
          provide: APP_INITIALIZER,
          useFactory: initFactory,
          deps: [Injector], multi: true
        }
      ]
    };
  }
}
