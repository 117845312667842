<app-primary-nav id="home"></app-primary-nav>
<app-page-header sectionTitle="Get In Touch" backgroundImg="https://source.unsplash.com/600x400?drone"></app-page-header>
<section class="contactPage">
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col contactForm">
                <app-contact-form></app-contact-form>
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>