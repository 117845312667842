import { Injectable, OnDestroy, Inject } from '@angular/core';
import { ChangePasswordState, IUserStateService, Role, UserService, UserState } from '@thedevshop/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { UserSettings } from '@thedevshop/core/security';
import { Subscription, Subject, Observable, forkJoin, timer } from 'rxjs';
import { takeUntil, tap, mapTo, filter, map } from 'rxjs/operators';
import { ApplicationContext, AuthService } from '@thedevshop/web-ng';
import { ModelFactory, StateModel } from '@thedevshop/core';
import { ANON_TOKEN } from '@thedevshop/web-ng';

@Injectable()
export abstract class BaseUserStateService<T extends UserSettings, TState extends UserState<T>> implements IUserStateService, Resolve<boolean>, OnDestroy {
    protected destroy$: Subject<boolean> = new Subject<boolean>();
    public onUserLoggedOut$: Subject<boolean> = new Subject();
    public onUserLoaded$: Subject<TState> = new Subject();
    public onError$: Subject<TState> = new Subject();
    private validateTokenTimerSeconds = 300;
    private isValidateTokenRunning = false;
    private timerSubscription: Subscription;
    private lastUser: T;
    private lastSubscription: Subscription;
    private lastUpdate: Date = null;
    public model: StateModel<TState>;
    state$: Observable<TState>;
    protected get defaultState(): TState {
        return {} as TState;
    }
    constructor(protected userService: UserService<T>, @Inject(ANON_TOKEN) protected anonToken: string, protected authService: AuthService, private applicationContext: ApplicationContext, private modelFactory: ModelFactory<TState>) {
        if (!localStorage.getItem('token')) {
            localStorage.setItem('token', anonToken);
        }
        this.model = this.modelFactory.create(this.defaultState);
        this.state$ = this.model.data$.pipe(takeUntil(this.destroy$), map(state => {
            if (!state.IsLoggedIn) {
                this.isValidateTokenRunning = false;
            }
            return state;
        }));
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.resolveCurrentUser().pipe(tap(data => {
            const userState = this.model.get();
            userState.User = data.User;
            userState.Roles = data.Roles;
            userState.IsLoading = false;
            userState.IsLoggedIn = !!userState.User;
            this.setModel(userState);
        }), takeUntil(this.destroy$), mapTo(true));
    }
    public initialize() {
        const token = localStorage.getItem('token');
        this.setToken(token);
    }
    public setToken(token: string) {
        const state = this.model.get();
        state.Token = token ? token : this.anonToken;
        if (state.Token === this.anonToken) {
            state.IsLoggedIn = false;
        }
        this.setModel(state);
        if (state.Token && !state.User) {
            this.getCurrentUser();
        }
    }
    public expire(): void {
        let state = this.model.get();
        // state.IsUserInitialized = false;
        // NEVER expire the anon token
        if (this.anonToken && state.Token === this.anonToken) {
            return;
        }
        const logouts = [this.authService.logout(state.Token)];
    }
    public authenticate(username: string, password: string, isCustomer = true, newUser?: boolean, integration?: any): void {
        const state = this.model.get();
        state.Errors = null;
        state.IsLoading = true;
        this.setModel(state);
        // integration {
        // type: twitter
        // field: userId
        // value: 1234
        // }
        // If we're on a device, authenticate through the device
        // Otherwise authenticate directly
        const authPromise = this.authService.authenticate(username, password, '', integration).toPromise();
        authPromise.then(response => {
            let token = '';
            if (response.success) {

                localStorage.setItem('token', token);
                this.setModel(state);
                this.getCurrentUser();
            } else {
                state.IsLoading = false;
                state.Errors = response.messages ? response.messages.join('. ') : 'Failed to authenticate.';
                this.setModel(state);
                this.onError$.next(state);
            }
        }, err => {
            state.IsLoading = false;
            state.Errors = err;
            this.setModel(state);
            this.onError$.next(state);
        });
    }
    public updatePassword(currentPassword: string, newPassword: string, confirmPassword: string) {
        const state = this.model.get();
        state.ChangePasswordState = { Errors: null, HasPasswordChanged: false };
        if (newPassword !== confirmPassword) {
            state.ChangePasswordState.Errors = 'Passwords must match';
            this.model.set(state);
            return;
        }
        state.IsLoading = true;
        this.model.set(state);
        this.authService.updatePassword(state.Token, currentPassword, newPassword).toPromise().then(response => {
            if (response.success) {
                state.IsLoading = false;
                state.ChangePasswordState = { Errors: null, HasPasswordChanged: true };
                this.model.set(state);
            } else {
                state.IsLoading = false;
                state.ChangePasswordState = { Errors: response.messages.join(', '), HasPasswordChanged: false };
                this.model.set(state);
            }
        });
    }
    public getCurrentUser(): void {
        const state = this.model.get();
        state.IsLoading = true;
        this.setModel(state);
        // We have 2 tokens basically going around.  The user state one, nd the one the
        // auth service got from app context
        if (state.Token) {
            this.resolveCurrentUser()
                // .finally(() => {
                //     const state = this.model.get();
                //     state.IsUserInitialized = true;
                //     this.setModel(state, true);
                // })
                .toPromise()
                .then(userInfo => {
                    if (userInfo) {
                        state.User = userInfo.User;
                        state.Roles = userInfo.Roles;
                        state.IsLoggedIn = true;
                        this.lastUpdate = new Date();
                        if (state.Token && !this.isValidateTokenRunning) {
                            this.isValidateTokenRunning = true;
                            // Begin verifying the token every n seconds
                            this.timerSubscription = timer(0, this.validateTokenTimerSeconds * 1000).pipe(takeUntil(this.state$.pipe(filter(s => !s.IsLoggedIn)))).subscribe(timer => {
                                this.authService.validate(state.Token).subscribe(isValid => {
                                    if (!isValid) {
                                        // TODO we need to broad cast log out message so user knows what's going on
                                        const latestState = this.model.get();
                                        latestState.IsLoggedIn = false;
                                        latestState.User = null;
                                        latestState.Token = this.anonToken;
                                        this.setModel(latestState);

                                        // TODO: This needs revisited.  We're getting this when we get 500s
                                        // The token is not expired if we get a 500

                                        console.log('Your token has expired');
                                    }
                                });
                            });
                        }
                    } else {
                        if (this.timerSubscription) {
                            this.timerSubscription.unsubscribe();
                        }
                        state.IsLoggedIn = false;
                        localStorage.setItem('token', this.anonToken);
                    }
                    state.IsUserInitialized = true;
                    state.IsLoading = false;
                    this.onUserLoaded$.next(state);
                    this.setModel(state);
                }, err => {
                    {
                        state.IsLoggedIn = false;
                        // TODO: this is poorly named.  Maybe IsInitialized?
                        state.IsUserInitialized = true;
                        state.IsLoading = false;
                        this.onUserLoaded$.next(state);
                        this.setModel(state);
                    }
                });
        }
    }

    public setResetPasswordToken(confirmId: string) {
        const state = this.model.get();
        this.setModel(state);
    }

    public setCurrentUser(user: T) {
        const state = this.model.get();
        state.User = user;
        if (!state.User) {
            this.expire();
            state.Token = null;
        }
        this.setModel(state);
    }
    public save(user: T): Observable<T> {
        const state = this.model.get();
        state.IsLoading = true;
        this.setModel(state);
        return this.userService.save(user as any).pipe(map(userResponse => {
            this.lastUpdate = new Date();
            state.User = userResponse;
            // TODO: revisit
            // This is tripping up the diff below
            delete state.User.__i.auditing;
            delete (state.User.__i as any).acl;
            delete state.User.__i.tags;
            delete state.User.__i.model.version;
            this.setModel(state);
            return user;
        }));
    }
    public setReferredByCode(code: string) {
        const state = this.model.get();
        this.setModel(state);
      }
    
    private resolveCurrentUser(): Observable<{
        User: T;
        Roles: Role[];
    }> {
        return this.userService.getCurrentUserInfo();
    }
    private setModel(state: TState, force?: boolean) {
        if (!state.User || force) {
            state.IsLoggedIn = !!state.User;
            this.lastUser = state.User;
            this.model.set(state);
            return;
        }
        if (this.lastUser && state.User) {
            // We also need to load if token changed
            // For debugging diff this.difference(state.User, this.lastUser);
            const isSame = deepEquals(state.User, this.lastUser);
            if (isSame) {
                return;
            }
        }
        this.lastUser = state.User;
        state = this.updateModel(state);
        this.model.set(state);
    }
    protected updateModel(state: TState): TState {
        return state;
    }

}

export function deepEquals(x, y) {
    if (x === y) {
        return true; // if both x and y are null or undefined and exactly the same
    } else if (!(x instanceof Object) || !(y instanceof Object)) {
        return false; // if they are not strictly equal, they both need to be Objects
    } else if (x.constructor !== y.constructor) {
        // they must have the exact same prototype chain, the closest we can do is
        // test their constructor.
        return false;
    } else {
        for (const p in x) {
            if (!x.hasOwnProperty(p)) {
                continue; // other properties were tested using x.constructor === y.constructor
            }
            if (!y.hasOwnProperty(p)) {
                return false; // allows to compare x[ p ] and y[ p ] when set to undefined
            }
            if (x[p] === y[p]) {
                continue; // if they have the same strict value or identity then they are equal
            }
            if (typeof (x[p]) !== 'object') {
                return false; // Numbers, Strings, Functions, Booleans must be strictly equal
            }
            if (!deepEquals(x[p], y[p])) {
                return false;
            }
        }
        for (const p in y) {
            if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                return false;
            }
        }
        return true;
    }
}




@Injectable()
export class UserStateService extends BaseUserStateService<UserSettings, any> {


  constructor(
    appContext: ApplicationContext,
    @Inject(ANON_TOKEN) anonToken: string,
    applicationContext: ApplicationContext,
    modelFactory: ModelFactory<UserState<UserSettings>>,
  ) {
    super(new UserService(appContext), anonToken, null, applicationContext, modelFactory);
  }

  get defaultState(): any {
    return {
      User: null,
      Roles: [],
      Token: null,
      IsLoading: false,
      IsLoggedIn: false,
      IsRegistering: false,
      IsSubscribing: false,
      IsUserInitialized: false,
      IsRedeeming: false,
      Errors: null,
      ResetPasswordToken: null,
      RequiresPasswordReset: false,
      ChangePasswordState: new ChangePasswordState(),
      DiscountInfo: {}

    };
  }

  public setIsLoading(isLoading: boolean) {
    const state = this.getState();
    state.IsLoading = !!isLoading;
    this.setState(state);
  }




  protected getState(state?: any): any {
    return state || this.model.get();
  }

  protected setState(state: any): any {
    this.model.set(state);
    return state;
  }

  public setValues(state: any, newSize) {
    this.model.set(state);
    return state || this.model.get();
  }
}
