<section class="accolades py-6">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item"
                        src="https://w3.cdn.anvato.net/player/prod/v3/anvload.html?key=eyJtIjoiY2JzIiwidiI6IjQ2MzUwNjAiLCJhbnZhY2siOiI1VkQ2RXlkNmRqZXdiQ21Od0JGbnNKajE3WUF2R1J3bCIsInNoYXJlTGluayI6Imh0dHBzOi8vY2JzbG9jLmFsLzMyQlZ4ZVkiLCJwbHVnaW5zIjp7ImNvbXNjb3JlIjp7ImNsaWVudElkIjoiMzAwMDAyMyIsImMzIjoiUGl0dHNidXJnaC5jYnNsb2NhbC5jb20ifSwiZGZwIjp7ImNsaWVudFNpZGUiOnsiYWRUYWdVcmwiOiJodHRwOi8vcHViYWRzLmcuZG91YmxlY2xpY2submV0L2dhbXBhZC9hZHM%2Fc3o9MngyJml1PS80MTI4L0NCUy5QSVRUUyZjaXVfc3pzJmltcGw9cyZnZGZwX3JlcT0xJmVudj12cCZvdXRwdXQ9eG1sX3Zhc3QyJnVudmlld2VkX3Bvc2l0aW9uX3N0YXJ0PTEmdXJsPVtyZWZlcnJlcl91cmxdJmRlc2NyaXB0aW9uX3VybD1bZGVzY3JpcHRpb25fdXJsXSZjb3JyZWxhdG9yPVt0aW1lc3RhbXBdIiwia2V5VmFsdWVzIjp7ImNhdGVnb3JpZXMiOiJbW0NBVEVHT1JJRVNdXSIsInByb2dyYW0iOiJbW1BST0dSQU1fTkFNRV1dIiwic2l0ZVNlY3Rpb24iOiJmZWF0dXJlZCJ9fX0sIm1vYXQiOnsiY2xpZW50U2lkZSI6eyJwYXJ0bmVyQ29kZSI6ImNic2xvY2FsYW52YXRvdmlkZW8xODE3MzI2MDk0MzEifX0sImhlYXJ0YmVhdEJldGEiOnsiYWNjb3VudCI6ImNic2xvY2FsLWdsb2JhbC11bmlmaWVkIiwicHVibGlzaGVySWQiOiJjYnNsb2NhbCIsImpvYklkIjoic2NfdmEiLCJtYXJrZXRpbmdDbG91ZElkIjoiODIzQkEwMzM1NTY3NDk3RjdGMDAwMTAxQEFkb2JlT3JnIiwidHJhY2tpbmdTZXJ2ZXIiOiJjYnNkaWdpdGFsbWVkaWEuaGIub210cmRjLm5ldCIsImN1c3RvbVRyYWNraW5nU2VydmVyIjoiY2JzZGlnaXRhbG1lZGlhLmQxLnNjLm9tdHJkYy5uZXQiLCJjaGFwdGVyVHJhY2tpbmciOmZhbHNlLCJ2ZXJzaW9uIjoiMS41IiwicGFyZW50UGFnZVVSTCI6Imh0dHBzOi8vcGl0dHNidXJnaC5jYnNsb2NhbC5jb20vMjAyMC8wNy8yMC9lbGVjdHJvc3RhdGljLWRpc2luZmVjdGluZy1kcm9uZS8iLCJwYXJlbnRUaXRsZSI6IlBpdHRzYnVyZ2gtQXJlYSBDb21wYW55IERldmVsb3BzIEVsZWN0cm9zdGF0aWMgRHJvbmUgVGhhdCBDb3VsZCBEaXNpbmZlY3QgU3RhZGl1bXMgSW4gVGhyZWUgSG91cnMg4oCTIENCUyBQaXR0c2J1cmdoIiwicEluc3RhbmNlIjoicDAiLCJwcm9maWxlIjoiY2JzIiwiY3VzdG9tVHJhY2tpbmdTZXJ2ZXJTZWN1cmUiOiJjYnNkaWdpdGFsbWVkaWEuZDEuc2Mub210cmRjLm5ldCJ9LCJoZWFsdGhBbmFseXRpY3MiOnt9fSwiaHRtbDUiOnRydWUsInRva2VuIjoiZGVmYXVsdCJ9"
                        width="640" height="360"></iframe>
                    <!-- <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/432691012"></iframe> -->
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h2 class="mb-5">Featured In</h2>
                <div class="row align-items-center flipIn">
                    <div class="col network">
                        <a href="https://www.nbc.com/the-tonight-show" target="_blank"><img
                                src="assets/img/accolades/tonight_show.png"></a>
                    </div>
                    <div class="col network">
                        <a href="https://abc.com/" target="_blank"><img src="assets/img/accolades/abc.png"></a>
                    </div>
                    <div class="col network">
                        <a href="https://video.foxnews.com/v/6173986805001#sp=show-clips" target="_blank"><img
                                src="assets/img/accolades/foxnews.png"></a>
                    </div>
                    <div class="col network">
                        <a href="https://www.cbs.com/" target="_blank"><img src="assets/img/accolades/CBS-Logo.png"></a>
                    </div>

                </div>
                <div class="row align-items-center pt- flipIn">
                    <div class="col network">
                        <a href="https://facilityexecutive.com/2021/05/aeras-sanitization-drones-help-disinfection-at-kentucky-derby/"
                            target="_blank"><img src="assets/img/accolades/facility-executive.png"></a>
                    </div>
                    <div class="col network">
                        <a href="https://nypost.com/2020/07/21/company-develops-drone-that-can-disinfect-an-entire-stadium-in-3-hours/"
                            target="_blank"><img src="assets/img/accolades/New_York_Post_logo.png"></a>
                    </div>
                    <div class="col network">
                        <a href="https://www.ibtimes.com/" target="_blank"><img src="assets/img/accolades/ibt.jpg"></a>
                    </div>
                    <div class="col network">
                        <a href="https://www.yahoo.com/" target="_blank"><img src="assets/img/accolades/yahoo.png"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>