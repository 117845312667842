// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  organization: 'aerasfog',
  token: 'Kxuf8ydmEkWc705YO6aenQ==WbJ8TPQ5H0ytJeYiAW3m6Q==',
  firebase: {
    apiKey: 'AIzaSyBU_q869GzjGrprh1CHxWa86GGcBq5mq3o',
    authDomain: 'boompresstest.firebaseapp.com',
    databaseURL: 'https://boompresstest.firebaseio.com',
    projectId: 'boompresstest',
    storageBucket: 'boompresstest.appspot.com',
    messagingSenderId: '503405846837',
    appId: '1:503405846837:web:7abb9841de7a3f15aaec7c',
    measurementId: 'G-37YE2ENVVR'
  },
  analyticsId: 'UA-179477044-1',
  socketIoUrl: 'https://dkps.thedevshoptest.app/api',
  socketIoPath: '/socket.io',
  customerBaseUrl: 'https://aeras.thedevshoptest.app/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
