import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-primary-nav',
  templateUrl: './primary-nav.component.html',
  styleUrls: ['./primary-nav.component.scss']
})
export class PrimaryNavComponent implements OnInit {
  public activeSection: string;
  checked: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  hideNav() {
    this.checked = false;
  }

  setActiveSection(section: string) {
    if (section === this.activeSection) {
      return false;
    }
    this.activeSection = section;
  }

}
