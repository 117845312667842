<div class="technologyPage">
    <app-page-header sectionTitle="Technology" pageTitleClass="technology"
        sectionSubTitle="Pioneering Drone Capabilities" backgroundImg="assets/img/2022/AerasImage5Darkened.jpg">
    </app-page-header>
    <section class="copyBlock imgRight py-5">
        <div class="container">
            <div class="row align-items-center py-5">
                <div class="col-12 col-md-6">
                    <h3>CHARGED ELECTROSTATICS<br>
                        (patent-pending ip)</h3>
                    <p>Packing 80,000 volts, our charged electrostatic drone is the first and only one of its kind,
                        meaning it carries a voltage charge without disruption of the LIDAR, GPS, compass and other
                        electronic systems.
                        The use of nanotechnology works to shield the above systems from not only the charge we carry on
                        it,
                        but also from external sources (power lines, electrical storms, etc.) that will severely affect
                        other drones.</p>
                    <p>The AERAS charged-electrostatic drone will charge particles for over 60 seconds, versus the 1-2
                        seconds
                        from current friction-based systems on the market.</p>
                </div>
                <div class="col-12 col-md-6">
                    <img src="assets/img/2022/electrostatic.jpg" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="copyBlock imgLeft pb-5">
        <div class="container">
            <div class="row align-items-center py-5">
                <div class="col-12 col-md-6">
                    <img src="https://via.placeholder.com/400?text=Missing+Asset" class="img-fluid">
                </div>
                <div class="col-12 col-md-6">
                    <h3>AERAS ONE</h3>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo conse- quat. Duis autem vel eum iriure dolor in hendrerit in vul- putate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ec</p>
                    <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
                </div>
            </div>
        </div>
    </section> -->
    <section class="copyBlock imgRight pb-5">
        <div class="container">
            <div class="row align-items-center py-5">
                <div class="col-12 col-md-6">
                    <h3>WINDOWS + BUILDINGS<br>(Patented IP)</h3>
                    <p>AERAS drones have high-tech software including rolling radars and LIDAR systems to keep our units
                        away from buildings and other hazards. With its triple-redundant safety features it is almost
                        impossible for our drone to crash or “fly away” like early technology drones. Currently, we are
                        able to outfit our drones with 3,400 psi and spray at heights in excess 150 feet. A solution of
                        the client’s choice can be added to our high-pressure systems whether it be detergents or
                        other chemical makeups.</p>
                    <p>With the patented AERAS Window Washing Drone we can identify any window using the latest
                        artificial intelligence, which allows us to quickly, safely and autonomously clean windows
                        without risk to human life at speeds of more than 5 times faster than current manned methods.</p>
                </div>
                <div class="col-12 col-md-6">
                    <img src="assets/img/2022/windows.jpg" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="copyBlock imgLeft pb-5">
        <div class="container">
            <div class="row align-items-center py-5">
                <div class="col-12 col-md-6">
                    <img src="https://via.placeholder.com/400?text=Missing+Asset" class="img-fluid">
                </div>
                <div class="col-12 col-md-6">
                    <h3>AERAS ONE</h3>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo conse- quat. Duis autem vel eum iriure dolor in hendrerit in vul- putate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ec</p>
                    <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
                </div>
            </div>
        </div>
    </section> -->
</div>