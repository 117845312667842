import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductStateService } from '../../core/product-state.service';

@Component({
  selector: 'app-technology-page',
  templateUrl: './technology-page.component.html',
  styleUrls: ['./technology-page.component.scss']
})
export class TechnologyPageComponent implements OnInit {
  public products;
  constructor(private productStateService: ProductStateService,
              private router: Router) { }

  ngOnInit(): void {
    this.productStateService.state$.subscribe(productState => 
      this.products = productState.products
    )
  }

  selectProduct(product) {
    this.productStateService.patchState({ currentProduct: product })
    this.router.navigateByUrl(`/product-page?id=${product.id}`);
  }

}
