
<app-page-header sectionTitle="contact us" pageTitleClass="contact" sectionSubTitle=" "></app-page-header>
<section class="contactPage">
    <div class="container pt-5">
        <div class="row py-5">
            <div class="col-12 col-md-6">
                <h1>Contact Us</h1>
                <p>Thank you for reaching out to the AERAS team.</p>
                <p class="m-0 bold"><b>General Business & Sales</b></p>
                <p class="accent">info@aerasfog.com</p>
                <p class="m-0 bold"><b>AERAS HQ</b></p>
                <p>136 S. Jackson St.<br> Evans City, Pa 16033</p>
                <p>412.448.2820</p>
            </div>
            <div class="col-12 col-md-6 contactForm">
                <app-contact-form></app-contact-form>
            </div>
        </div>
    </div>
</section>