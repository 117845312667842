<section class="videoHero">
    <img src="assets/img/topo.svg" class="texture-overlay animate__animated animate__fadeIn animate__slow">
    <img src="assets/img/videoHeader.jpg" class="texture-overlay d-block d-sm-none animate__animated animate__fadeIn animate__slow" style="position: absolute; top: 0; right: 0;">
    <video autoplay loop playsinline muted oncanplay="this.play()" onloadedmetadata="this.muted = true" class="d-none d-md-block d-lg-block" poster="assets/img/videoHeader.jpg">
        <source src="assets/video/aeras_kentucky_derby_2021.mp4" type="video/mp4">
        <source src="assets/video/aeras_kentucky_derby_2021.webm" type="video/webm">
    </video>
    <div class="viewportHero">
        <h1 class="animate__animated animate__slideInUp">
            PATENTED  
            <span>ELECTROSTATIC TECHNOLOGY</span>
        </h1>
        <a [routerLink]="['/technology']" class="btn btn-primary">Learn More</a>
    </div>
</section>