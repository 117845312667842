import { Injectable } from '@angular/core';
import { StateModel, ModelFactory, TranslationService } from '@thedevshop/core';
import { Observable } from 'rxjs';
import { BaseStateService } from './base-state.service';
import { map } from 'rxjs/operators';

export class ProductState {
  products: Array<any>;
  currentProduct: any;
  isLoading: boolean;
  isHiddenCode: boolean;
  discount: {
    code: string;
    name: string;
    price: number;
    isApplied: boolean;
    isFree: boolean;
    error: string;
    description?: string;
  };
  discountDescription: string;


  constructor() {
    this.products = [];
    this.currentProduct = null;
  }
}


@Injectable({
  providedIn: 'root',
})
export class ProductStateService extends BaseStateService<ProductState> {


  constructor(
    translationService: TranslationService,
    stateFactory: ModelFactory<ProductState>,

  ) {
    super(new ProductState(), stateFactory, translationService);

    this.initialize();
  }


  public stateModel: StateModel<ProductState>;
  public state$: Observable<ProductState>;



  public initialize() {
    const state = this.getState();
    state.discount = {
      isApplied: false,
      name: null,
      code: null,
      isFree: false,
      price: null,
      error: null
    };
    // TODO: Fetch real data
    state.products = [
      {
        id: 1,
        productName: 'Electrostatic Drone Service',
        productPrice: 'Contact Us for Pricing Information',
        productDescriptionShort: 'Our patent pending technology brings the world’s first truly charged electrostatic drone that is capable of substantially reducing risk associated with the transmission of any pathogen, including Covid-19.',
        productDescriptionLong: '<h2>Tech Specs</h2><ul><li>Drone equip with patent-pending electrostatic sprayer</li><li>Electrostatically charged particles achieve a 75x value greater than the force of gravity</li><li>Maintains a particle charge 100x longer than existing technologies</li><li>Internally fabricated components to account for any changes in environmental condition</li><li>Aeras Fog’s proprietary software can change voltage at the touch of a button to safely spray anything from empty seats to sensitive electronic equipment</li><li>Autonomous 3D topographical mapping FAA Approved</li><li>Indoor/Outdoor sanitization capabilities 20 acres/coverage per hour</li></ul>',
        productImgUrl: 'assets/img/products/scDrone.jpg',
        isAvailable: false,
      },
      {
        id: 2,
        productName: 'AERAS Backpack',
        productPrice: 'Contact Us for Pricing Information',
        productDescriptionShort: 'We have developed a commercial backpack delivery system that sets the industry standard in effectiveness.  The Aeras Backpack maintains an electrostatic charge 6x longer, produces 93 CFM rate of fluid disbursement, is adjustable down to 5 micros (representing a 10x improvement vs competing products) and represents the first of its kind to offer interchangeable lithium ion batteries.',
        productDescriptionLong: '<p>Aeras has developed a one-of-a-kind electrostatic cordless backpack sprayer with a custom designed brushless motor system. Our backpacks are capable of a maximum coverage of 1,500 sq. ft. maintaining an electrostatic charge for up to 60 seconds, providing total coverage. The backpack system has an adjustable micron flow rate from 5-50 microns.</p><h2>Tech Specs</h2><ul><li>Only electrostatic backpack sprayer with interchangeable batteries</li>     <li>Custom designed brushless motor system</li>        <li>Maximum coverage of 1,500 sq. ft. per minute</li><li>Particles charged for 60 seconds</li>    <li>Adjustable flow rate varies from 5-50 microns allowing for the safe disinfection of areas with electronics, servers and legal documents</li>        </ul>',
        productImgUrl: 'assets/img/products/backpack.jpg',
        isAvailable: false,
      },
      {
        id: 3,
        productName: 'AERAS mini',
        productPrice: '$399 Retail',
        productDescriptionShort: 'We are also pleased to introduce the most advanced handheld sprayers on the market in the Aeras mini.  Recommended for smaller footprints, this product maintains a charge for 3x competitors commercial versions at 68% lower cost.  The Aeras mini is perfect for automobiles, residences, and all personal spaces.',
        productDescriptionLong: 'The Aeras mini was designed for personal use for the food and beverage, retail, hospitality, real estate, cruiseline. The handheld unit effectively covers surfaces and surrounding areas by electrostatically charging the deployed particles. This allows any solution to uniformly wrap surfaces ensuring complete coverage. We have created the most convenient solution on the market to ensure the highest level of safety in any small enclosed environment. <br><br>Effective for all surfaces including door/cabinet handles, remotes, bookcases, bathroom and kitchen surfaces.  The Aeras mini is a cordless unit chargeable via any standard electrical 120v outlet or USB port.  The product does not require a specific solution or liquid to be sprayed, holds up to 20 oz. of liquid and is dishwasher safe.<br><br>The Aeras mini is also perfect for private residences, cars, boats, planes, and any smaller confined areas. There are a number of EPA certified solutions that are safe to use around food and produce. We created the Aeras mini to compliment the commercial backpack for companies and to provide the most effective application for individual consumer applications.  Our handheld unit maintains a charge 3x longer than competitors’ handheld units that cost in excess of $1,000, making the Aeras mini not only the most effective, but also the most economical option on the market.  We wanted a product that would be affordable for everyone and as such, we have priced the Aeras mini at less than half the cost of less efficient handheld units.',
        productImgUrl: 'assets/img/products/handheld.jpg',
        isAvailable: false,
      }
    ];
    this.setState(state);
  }



  setCurrentProductById(id: number) {
    const state = this.getState();
    state.currentProduct = state.products.find(p => p.id == id);
    this.setState(state);
  }

}
