<app-page-header sectionTitle="Services" backgroundImg="assets/img/techHeader.jpg"></app-page-header>
<section class="servicesPage pb-5">
    <div class="container">
        <div class="row">
            <div class="col">
                <h4>Electrostatic Drone Service</h4>
                <p>The Aeras Fog Electrostatic Disinfecting Drone service is the first and only of its kind. Our patent-pending electrostatic drone system is capable of charging particles in excess of 60,000v, maintaining for over 60 seconds during the application
                    process. The result of spraying with our drone allows any desired solution to reach and wrap around (both above AND below) surfaces for a full coverage and uniformed result.</p>
                <p>Aeras Fog is capable of disinfecting large indoor and outdoor areas through its vast network of certified drone pilots. The result is the complete elimination of any potential human error, a substantial reduction in time required and reduced
                    costs.
                </p>
            </div>
        </div>
        <div class="row py-3">
            <div class="col">
                <p>Our fleet of drones are capable of disinfecting 30+ acres (1,200,000+ square feet) per hour, allowing us to disinfect entire stadiums in less than 3 hours. With the development of this drone, we are pioneering the new standard in electrostatic
                    delivery systems for the sanitation and agricultural industries. Stated simply, we offer the most effective and advanced delivery systems available.</p>
            </div>
        </div>
        <app-cta buttonText="Disinfect Now!"></app-cta>
        <div class="row py-5 align-items-center">
            <div class="col-12 col-md-6">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/432691012"></iframe>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h5>Drone Tech Specs:</h5>
                <ul>
                    <li>Maintains a particle charge 20x longer than existing technologies</li>
                    <li>Electrostatically charged particles achieve a 75x value greater than the force of gravity</li>
                    <li>Drone equip with patent-pending electrostatic sprayer system</li>
                    <li>Autonomous 3D topographical mapping</li>
                    <li>Triple-redundant safety protocols</li>
                    <li>Experienced pilots with flawless operational records</li>
                    <li>Indoor/Outdoor sanitization capabilities</li>
                </ul>
            </div>
        </div>

        <div class="row pt-3">
            <div class="col">
                <p><strong>PLEASE NOTE: Aeras Fog is a service company, due to recommendations from the FAA, EPA and FBI. NO DRONES are for sale to the public.</strong></p>
            </div>
        </div>
    </div>
</section>