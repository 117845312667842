import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../../core/services';
import { USER_STATE_SERVICE, UserStateService } from '@thedevshop/web-ng';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  isError: boolean = false;
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  message: string = null;

  constructor(
  private messageService: MessageService,
  fb: FormBuilder,
  ) {  
    this.contactForm = fb.group({
      email: [null, Validators.compose([Validators.required])],
      firstName: [null, Validators.compose([Validators.required])],
      lastName: [null, Validators.compose([Validators.required])],
      company: [],
      phone: [
        null, 
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\+?[0-9\s\-\(\)]{1,20}$/) // Up to 20 characters: digits, spaces, hyphens, parentheses, optional + allowed
        ])
      ],
      notes: [null, Validators.compose([Validators.required])],
  });
  } 

  ngOnInit(): void {

  }

  submitForm() {
    this.isLoading = true;
    
    this.messageService.contactUs(this.contactForm.value).subscribe(response => {
      if (this.contactForm.invalid) {
        this.isError = true;
        this.isSubmitted = false;
        this.message = 'Please fill out all fields.';
        this.isLoading = true;
        return;
      }
      if (response && response.success) {
        this.isSubmitted = true;
        this.isError = false;
        this.isLoading = false;
        this.contactForm.reset();
        this.message = 'Message has been sent! We will reach out to you as soon as possible. Thank you for your interest.';
      } else {
        this.isError = true;
        this.isLoading = false;  
        this.message = 'Failed to send message. Please try again.';
      }
    }, (err) => {
      this.isError = true;
      this.isLoading = false;
      this.message = 'Failed to send message. Please try again.';
    });
  }
}
