<!-- <app-cart-header></app-cart-header> -->
<header class="primaryNav position-fixed py-3">
    <nav class="navbar navbar-expand-lg navbar-light align-items-center" id="navbar">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.svg"></a>
        <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu" [(ngModel)]="checked">
        <label for="openSidebarMenu" class="sidebarIconToggle">
            <div class="spinner diagonal part-1"></div>
            <div class="spinner horizontal"></div>
            <div class="spinner diagonal part-2"></div>
        </label>
        <div class="ml-lg-5" id="sidebarMenu">
            <ul class="navbar-nav ml-auto sidebarMenuInner">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/technology" (click)="hideNav()">Technology</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/capabilities" (click)="hideNav()">Capabilities</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/community" (click)="hideNav()">Community</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/about" (click)="hideNav()">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/contact" (click)="hideNav()">Contact Us</a>
                </li>
            </ul>
        </div>
    </nav>
</header>
<div id="startchange"></div>