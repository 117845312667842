<app-page-header sectionTitle="Products" pageTitleClass="productsPage" backgroundImg="assets/img/techHeader.jpg"></app-page-header>
<section class="productListPage py-5">
    <div class="container">
        <div class="row">
            <div class="col">
                <p>While the Aeras Electrostatic Drone was initially our full focus and first creation, being the technical savants we are we found that many of the products in the electrostatic industry were not as good as they could be. So we got to work
                    and created two new products that are innovative to the industry standard. Now with vertically integrated options we are able to assist our customers and ensure the best sanitation services and products are offered. </p>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-4 products flipIn" *ngFor="let product of products">
                <img [src]="product.productImgUrl">
                <h4>{{product.productName}}<span>{{product.productPrice}}</span></h4>
                <p>{{product.productDescriptionShort}}</p>
                <a (click)="selectProduct(product)" class="btn btn-outline-primary">Learn More</a>
            </div>

        </div>
    </div>
</section>