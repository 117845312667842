import { StateModel, ModelFactory, TranslationService } from '@thedevshop/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IBaseStateService<T> {
  stateModel: StateModel<T>;
  state$: Observable<T>;
  onChange: Subject<T>;
}

export class BaseStateService<T> implements IBaseStateService<T> {
  protected t: any; // Translations;
  public stateModel: StateModel<T>;
  public state$: Observable<T>;
  public onChange: Subject<T>;

  constructor(
    t: T,
    protected stateFactory: ModelFactory<T>,
    translationsService: TranslationService) {
    this.stateModel = stateFactory.create(t);
    this.state$ = this.stateModel.data$.pipe(map(items => items));
    if (translationsService) {
      this.t = translationsService.get();
    }
    this.onChange = new Subject<T>();
  }

  
  protected getState(state?: T): T {
    return state || this.stateModel.get();
  }

  protected setState(state: T): T {
    this.stateModel.set(state);
    this.onChange.next(state);
    return state;
  }

  public patchState(partialState: Partial<T>, state?: T): T {
    state = this.getState(state);
    const patchedState = Object.assign(state, partialState);
    return this.setState(patchedState);
  }

}
