import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() sectionTitle: string;
  @Input() sectionSubTitle: string;
  @Input() backgroundImg: string;
  @Input() pageTitleClass: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
