import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public activeSection: string;

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
  }
  
  hasRoute(route: string) {
    return this.router.url.includes(route);
  }
}
