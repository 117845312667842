<app-page-header sectionTitle="Cart" backgroundImg="assets/img/techHeader.jpg"></app-page-header>
<section class="checkoutPage py-5">
    <div class="container">

        <div class="shopping-cart">

            <div class="column-labels">
                <!-- <label class="product-image">Image</label> -->
                <label class="product-details">Product</label>
                <label class="product-price">Price</label>
                <label class="product-quantity">Quantity</label>
                <label class="product-removal">Remove</label>
                <label class="product-line-price">Total</label>
            </div>

            <div class="product">
                <!-- <div class="product-image">
                    <img src="assets/img/tech-drone.png">
                </div> -->
                <div class="product-details">
                    <div class="product-title">Fogger Product</div>
                    <p class="product-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id augue
                        et eros iaculis finibus. Maecenas felis sem, cursus ac iaculis nec, finibus id mi.</p>
                </div>
                <div class="d-flex align-items-center w-100">
                    <div class="product-price">999.99</div>
                    <div class="product-quantity">
                        <input type="number" value="2" min="1">
                    </div>
                    <div class="product-removal">
                        <button class="remove-product">
                            Remove
                        </button>
                    </div>
                    <div class="product-line-price text-end">999.99</div>
                </div>

            </div>

            <div class="totals">
                <div class="totals-item">
                    <label>Subtotal</label>
                    <div class="totals-value" id="cart-subtotal">999.99</div>
                </div>
                <div class="totals-item">
                    <label>Tax (5%)</label>
                    <div class="totals-value" id="cart-tax">3.60</div>
                </div>
                <div class="totals-item">
                    <label>Shipping</label>
                    <div class="totals-value" id="cart-shipping">15.00</div>
                </div>
                <div class="totals-item totals-item-total">
                    <label>Grand Total</label>
                    <div class="totals-value" id="cart-total">1018.59</div>
                </div>
            </div>
            <div class="text-right">
                <button class="btn btn-primary">Checkout</button>
            </div>
        </div>
    </div>
</section>