<div class="capabilitiesPage">
    <app-page-header sectionTitle="CAPABILITIES" pageTitleClass="capabilities" sectionSubTitle="SCIENCE MEETS COMMON SENSE"
        backgroundImg="assets/img/2022/AerasSub2Darkened.jpg"></app-page-header>
    <section class="capabilitesNav py-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="list-inline">
                        <li class="list-inline-item">Precision Agriculture</li>
                        <li class="list-inline-item">SANITIZATION + POWER WASHING</li>
                        <li class="list-inline-item">SURVEYING + INSPECTION</li>
                        <li class="list-inline-item">MULTIMEDIA</li>
                        <li class="list-inline-item">WILDFIRE MITIGATION</li>
                        <li class="list-inline-item">PUBLIC WORKS</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="capabilitiesHeader py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <h3>What are we capable of?</h3>
                    <p>AERAS was founded in 2020 with the goal to bring people back to stadiums and arenas during a time period when this seemed to be unrealistic. 
                        We soon realized that our capabilities and ambitions could stretch to much greater heights.
                    </p>
                  
                </div>
                <div class="col-12 col-md-6 text-center">
                    <img src="assets/img/2022/capabilities-photo-01.jpg" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <section class="capabilitiesList py-5">
        <div class="container">
            <div class="row py-3" name="#precision">
                <div class="col-12">
                    <h3>Precision Agriculture</h3>
                    <p>Utilizing AER-Force technology, our drones provide precise pesticide and herbicide applications, leaf-patterned recognition, canopy penetration and tree and crop planting capabilities. 
                        Not only does this provide a more effective solution for application, there is no drift or waste with our electrostatic technology.</p>
                    <!-- <a href="#link">Downloadable White Paper PDF</a> -->
                </div>
            </div>
            <div class="row py-3 align-items-center" id="#sanitization">
                <div class="col">
                    <h3>Precision LiDAR & Photogrammetry </h3>
                    <p>LiDAR (Light Detection and Ranging) uses sensory technology, emitted from a laser, to scan the ground and measure variable distances. 
                        This technology has been used since the 1960s, where it was first used by engineers and surveyors to develop exceptionally high quality 
                        topographical maps that included roads, bridges, rivers and other ground features.</p>
                    <p>LiDAR results provide a detailed set of elevation data points that can be developed into maps, models, and other objects.</p>
                    <p>Photogrammetry is the art and science of extracting 3D information from photographs. The process involves taking overlapping 
                        photographs of an object, structure, or space, and converting them into 2D or 3D digital models.</p>
                </div>
                <div class="col text-center d-none d-lg-block">
                    <img src="assets/img/2022/thermal-img-01-editted.jpg" class="img-fluid">
                </div>
            </div>
            <div class="row py-3" id="#surveying">
                <div class="col-12">
                    <h3>3D Mapping</h3>
                    <p>Aeras UAVs can produce digital surface or terrain models, contours, textured 3D models and vector information. 
                        Our fleet of drones for 3D Mapping are state-of-the-art when it comes to quality, reliability, and precision.</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col text-center">
                    <img src="assets/img/2022/3d-bridge-map-02-editted.png" class="img-fluid">
                </div>
            </div>
            <div class="row py-3" id="#multimedia">
                <div class="col-12">
                    <h3>Pressure Washing Drone (Buildings, Skyscrapers, Windows, Residential)</h3>
                    <p>Our fleet of Power Washing drones emphasize precision, speed, as well as mitigate risk. The Trident I & II allow 
                        power washing to be done in a way that it has never been done before, and in a fraction of the time. 
                        Our drones are the first in this space to truly be considered a “Pressure-washing” drone. Emitting 5000psi at 10gpm, 
                        we have established ourselves as the most powerful Pressure-Washing drone on the market. </p>
                </div>
            </div>
            <div class="row py-3" id="#wildfires">
                <div class="col-12">
                    <h3>Wildfire Mitigation</h3>
                    <p>Our proprietary technology gives our drones the ability to fly within two inches of a powerline. Due to the amount of 
                        voltage that the powerline gives off, a drone was never able to get this close. This ability allows us the advantage of 
                        collecting the brush buildup beneath the power lines, which is oftentimes a factor in the controlling and spreading of Wildfires.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>Sanitization: Venues & Large Areas</h3>
                    <p>AERAS invented and patented the first and only electrostatic drone in the world. The development of our AERForce 1, 
                        has not only added validity to our story, but has also allowed us to contract some of the most notable sporting venues 
                        in the country. Our AERForce 1 drone initially focused on sanitization of venues, arenas, and large areas in a fragment 
                        of the time that it would take a team of people.</p>
                </div>
            </div>
            <div class="row py-3 align-items-center" name="#publicworks">
                <div class="col">
                    <h3>Thermal Imaging: Search & Rescue</h3>
                    <p>AERAS’ drone-based, thermal sensing capabilities require no external light source. Our drone has the ability to fly both in 
                        the day and at night, and is designed to attain data points that indicate temperature readings. </p>
                    <p>First responders who are on Search & Rescue missions can safely search for survivors without risking their own lives. </p>
                </div>
                <div class="col text-center d-none d-lg-block">
                    <img src="assets/img/2022/search-rescue-02.jpg" class="img-fluid">
                </div>
            </div>
            <!-- <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>PHOTOGRAMMETRY</h3>
                    <p>AERAS uses precise imagery to create photorealistic 3D representations of surfaces through both
                        metric and interpretative photogrammetry. These capabilities are perfect for the surveying and
                        GIS, construction, real estate, engineering and energy industries.</p>
                </div>
            </div> -->
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>Hyper-Spectral Imaging</h3>
                    <p>AERAS drones are equipped with hyper-spectral cameras. These cameras can be used for a multitude of applications. 
                        These applications include, and are not limited to detecting plant and crop health, soil fertility, carbon dioxide 
                        detection, as well as many more.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>Photography & Video Production</h3>
                    <p>AERAS one-of-a-kind technology can tolerate even the harshest of environments while shooting one continuous shot in 
                        a fraction of the time. AERAS drones are capable of high-altitude aerial videos, smooth, low-altitude shots, indoor 
                        flights and more.</p>
                    <!-- <a href="#link">Downloadable White Paper PDF</a> -->
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col">
                    <h3>Public Safety</h3>
                    <p>AERAS has teamed up with both local and national law enforcement agencies to help support initiatives and save lives. 
                        We believe that technology should be used for the good. </p>
                    <p>Our technology provides first responders the ability to find information in real time which can help in many instances, 
                        including the reconstruction of accidents.</p>
                </div>
            </div>
            <!-- <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>SEARCH & RESCUE</h3>
                    <p>First responders can safely search for victims and survivors without risking their lives when
                        they partner with AERAS and our team of experts.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>FIRE RESCUE</h3>
                    <p>Did you know it’s possible to use an AERAS drone to identify hotspot sources of explosions in a
                        five-alarm blaze and to monitor wildfires from safe distances?</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>EFFICIENT INSPECTIONS</h3>
                    <p>AERAS drones can complete quality and detailed inspections with all the necessary documentation needed.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>BRIDGE INSPECTIONS</h3>
                    <p>AERAS drones have the capability to determine deterioration of bridge sections without risking the lives of inspectors.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>MINE INSPECTIONS</h3>
                    <p>New, indoor, autonomous flight software can duplicate 1cm precision mapping.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>PIPELINE INSPECTIONS</h3>
                    <p>AERAS' drone can identify issues earlier, keeping workers safe and helping find preventative measures to be taken.</p>
                </div>
            </div>
            <div class="row py-3" name="#publicworks">
                <div class="col-12">
                    <h3>STRUCTURAL INSPECTIONS</h3>
                    <p>View sources of inefficiencies for facilities management and documentation.</p>
                </div>
            </div> -->
        </div>
    </section>

</div>